<template> 
  <v-container  class="ma-auto" fluid>
    <v-form ref="form" v-model="valid" v-if="user && isLoggedIn" lazy-validation @submit.prevent="submit">
        <div class="pa-0 ma-0">
            <v-text-field
                v-model="UpdatedItem.titleEN"
                color="custom-accent darken-4"
                :label="$t('titleEN')"
                :placeholder="$t('titleEN')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <v-text-field
                v-model="UpdatedItem.titleFR"
                color="custom-accent darken-4"
                :label="$t('titleFR')"
                :placeholder="$t('titleFR')"
                required
                :counter="maxTextfieldLength"
                :rules="textfieldRules"
            ></v-text-field>
            <tiptap-vuetify
                class="mt-4"
                v-model="UpdatedItem.descriptionEN"
                :extensions="extensions"
                :placeholder="$t('descriptionEN')"
            ></tiptap-vuetify>
            <v-row>
                <v-col cols="8">
                    <v-input
                        :error-messages="textareaError(UpdatedItem.descriptionEN)"
                        error>
                    </v-input>
                </v-col>
                <v-col cols="auto" class="ml-auto">
                    <v-input
                        :error-messages="textareaCounter(UpdatedItem.descriptionEN)"
                        error
                        :disabled="textareaError(UpdatedItem.descriptionEN)==null">
                    </v-input>
                </v-col>
            </v-row>
            <tiptap-vuetify
                class="mt-4"
                v-model="UpdatedItem.descriptionFR"
                :extensions="extensions"
                :placeholder="$t('descriptionFR')"
            ></tiptap-vuetify>
            <v-row>
                <v-col cols="8">
                    <v-input
                        :error-messages="textareaError(UpdatedItem.descriptionFR)"
                        error>
                    </v-input>
                </v-col>
                <v-col cols="auto" class="ml-auto">
                    <v-input
                        :error-messages="textareaCounter(UpdatedItem.descriptionFR)"
                        error
                        :disabled="textareaError(UpdatedItem.descriptionFR)==null">
                    </v-input>
                </v-col>
            </v-row>
        </div>
    </v-form>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { TiptapVuetify, History, Paragraph, Bold, Italic, Underline, Link, ListItem, BulletList, OrderedList, Heading, Strike, Code, HorizontalRule, HardBreak } from 'tiptap-vuetify';

export default({
    
    props: ['UpdatedItem'],
    data() {
        return {
            valid: false,
            extensions: [
                History,
                Paragraph,
                Bold,
                Italic,
                Underline,
                Link,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                    levels: [1, 2, 3]
                    }
                }],
                Strike,
                Code,
                HorizontalRule,
                HardBreak
            ],
        }
    },
    components: { TiptapVuetify },
    computed: {
        maxTextfieldLength () {
        return 200;
        },
        maxTextareaLength () {
        return 3500;
        },
        textfieldRules(){ 
            return [
                v => !!v || this.$t('fieldError'),
                v => v?.length <= this.maxTextfieldLength || this.$t('fieldValidError1')+' '+this.maxTextfieldLength+' '+this.$t('fieldValidError2'),
            ]
        },

        ...mapGetters('user',{user:'getUser',isLoggedIn:'isLoggedIn'}),
    },
    watch:{
        UpdatedItem: {
            handler(){
                this.validate();
            }, deep: true 
        },
    },
    methods: {
        textareaError(item){ 
            if(item?.replace(/<\/?[^>]+(>|$)/g, '').length==0 || !item)
            {
                return this.$t('fieldError');
            }
            else if(item?.replace(/<\/?[^>]+(>|$)/g, '').length>this.maxTextareaLength){
                return this.$t('fieldValidError1')+' '+this.maxTextareaLength+' '+this.$t('fieldValidError2');
            }
            return null;
        },
        textareaCounter(item){ 
            if(item?.replace(/<\/?[^>]+(>|$)/g, '').length){
                return item.replace(/<\/?[^>]+(>|$)/g, '').length+'/'+this.maxTextareaLength;
            }
            return '0/'+this.maxTextareaLength;
        },
        deleteFile(){
            this.$emit('deleteFile');
        },
        validate(){
            let isValid = this.$refs.form.validate() && this.textareaError(this.UpdatedItem.descriptionEN)==null && this.textareaError(this.UpdatedItem.descriptionFR)==null;
            this.$emit('isValid','update',isValid);
        }
    }
})
</script>
<style scoped>
</style>
