<template>
  <v-container id="jobseeker" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + storage_url+path+'job-seeker-bg.jpg' + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-white--text text-uppercase" style="-webkit-text-stroke: 0.4px black;">{{
              $t("opportunities")
            }}</h1>
          </v-col>
        </v-row>
        <transition name="fade" mode="out-in" v-if="opportunity_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center">
            <v-col
              v-for="i in itemsperpage"
              :key="i"
              cols="12"
              sm="6"
              md="3"
            >
              <v-skeleton-loader
                type="image"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="opportunities.length==0 && !opportunity_loading">
          <v-row class="text-center pa-12 ma-auto my-7" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}.</i></v-row>
        </transition>
        <transition name="fade" mode="out-in" v-if="opportunities.length && !opportunity_loading">
          <v-row class="pa-8 ma-0 mt-8" justify="center" :key="itemsperpage * page">
            <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('opportunities.create')"
              cols="12">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateOpportunity()" text outlined :title="$t('create')"><v-icon class="text--secondary" large>mdi-plus-box</v-icon></v-btn>
                </template>
                <span>{{$t('create')}}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-for="(item, i) in opportunities.slice(
                itemsperpage * (page - 1),
                itemsperpage * page
              )"
              :key="i"
              cols="12"
              sm="6"
              md="3"
            >
              <v-card
                class="elevation-0"
                outlined
                height="100%"
                @click="ViewOpportunity(item)"
              >
                <div
                  class="text-center text-sm-left"
                >
                  <div>
                    <v-card-title class="mb-2 custom-accent--text"><span class="v-html-line-clamp-1">{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span></v-card-title>
                    <v-card-subtitle class="subtitle-2">{{$t("by")}} <span class="text-capitalize" v-if="item.author">{{$getObjectById(users,item.author).fullname}}</span><span v-else>{{$t('admin')}}</span> | {{item.created_at | formatDate}}</v-card-subtitle>
                    <v-card-text class="py-0"><span class="v-html-line-clamp-4" v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span></v-card-text>
                    <v-card-actions>
                      <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('opportunities.update')">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateOpportunity(item)" :title="$t('update')">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('update')}}</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('opportunities.delete')">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteOpportunity(item)" :title="$t('delete')">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('delete')}}</span>
                      </v-tooltip>
                      <v-btn color="custom-accent" class="ml-auto" text @click.stop="ViewOpportunity(item)" :title="$t('applyforjob')">{{$t("applyforjob")}}</v-btn>
                    </v-card-actions>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </transition>
        <v-row class="pa-0 ma-0 mb-8">
          <v-col v-if="Math.ceil(opportunities.length / itemsperpage)>1">
            <v-pagination
              v-model="page"
              :length="Math.ceil(opportunities.length / itemsperpage)"
              color="custom-accent"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-col>
        </v-row>

        <!-- view dialog -->
        <v-dialog v-model="opportunitydetailsoverlay" width="1100px" scrollable>
          <v-card class="pa-2 overflow-hidden" v-if="opportunitydetailsoverlay">
            <v-card-title class="pa-0 ma-0 pa-4 mr-6">
              <span class="text-h5 custom-accent--text">{{
                $i18n.locale == "en"
                  ? activeopportunity.titleEN
                  : activeopportunity.titleFR
              }}</span>
              <v-tooltip left color="custom-secondary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    absolute
                    top
                    right
                    style="z-index: 3;"
                    v-bind="attrs"
                    v-on="on"
                    :title="$t('close')"
                    @click="opportunitydetailsoverlay = false"
                    icon
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("close") }}</span>
              </v-tooltip>
            </v-card-title>
            <v-card-subtitle class="subtitle-2 pa-0 ma-0 mx-4 custom-secondary--text">{{$t("by")}} <span class="text-capitalize" v-if="activeopportunity.author">{{$getObjectById(users,activeopportunity.author).fullname}}</span><span v-else>{{$t('admin')}}</span> | {{activeopportunity.created_at | formatDate}}</v-card-subtitle>
            <v-divider class="my-4"></v-divider>
            <v-card-text class="subtitle-2 custom-secondary--text">
              <span v-html="$i18n.locale == 'en'? activeopportunity.descriptionEN: activeopportunity.descriptionFR"></span>
              <span class="text-uppercase text-h6 custom-accent--text">{{$t("applyforjob")}}</span>
              <contact-us-form class="my-5" :isDark="false" :type="'jobseeker'"></contact-us-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- view dialog -->

        <!-- create dialog -->
        <v-dialog v-model="opportunitycreateoverlay" width="800px">
          <v-card v-if="opportunitycreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <create-opportunity @isValid="validate" :NewItem="activeopportunity"></create-opportunity>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="opportunitycreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearOpportunity()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="opportunity_loading" :disabled="!opportunitycreateformvalid" @click="CreateOpportunity()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="opportunityupdateoverlay" width="800px">
          <v-card v-if="opportunityupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <update-opportunity @isValid="validate" :UpdatedItem="activeopportunity"></update-opportunity>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="opportunityupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="opportunity_loading" depressed :disabled="!opportunityupdateformvalid" @click="UpdateOpportunity()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="opportunitydeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="opportunitydeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="opportunity_loading" depressed @click="DeleteOpportunity()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../../components/Contact/ContactUsForm.vue';
import createopportunity from '../../components/Opportunity/CreateOpportunity.vue';
import updateopportunity from '../../components/Opportunity/UpdateOpportunity.vue';
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "JobSeeker",

  data() {
    return {
      opportunitydetailsoverlay: false,
      opportunitycreateoverlay: false,
      opportunityupdateoverlay: false,
      opportunitydeleteoverlay: false,
      opportunitycreateformvalid: false,
      opportunityupdateformvalid: false,
      activeopportunity: {},
      page: 1,
      itemsperpage: 4,

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "recruitments/jobseeker/images/",
    };
  },
  components: {
    'contact-us-form':contactusform,
    'create-opportunity':createopportunity,
    'update-opportunity':updateopportunity,
  },
  created() {
  },
  computed: {
    ...mapGetters("user", {users:'getUsers'}),
    ...mapGetters("user",{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('opportunity', {opportunities:'getOpportunities'}),
    ...mapState("opportunity", {opportunity_loading: "loading"}),
  },
  watch: {
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.opportunitycreateformvalid=isValid;
            break;

        case 'update':
            this.opportunityupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    ViewOpportunity(opportunity) {
      this.opportunitydetailsoverlay = true;
      this.activeopportunity = opportunity;
    },
    OpenCreateOpportunity() {
      this.opportunitycreateoverlay = true;
      this.activeopportunity = {};
    },
    OpenUpdateOpportunity(item) {
      this.opportunityupdateoverlay = true;
      this.activeopportunity = {...item};
    },
    OpenDeleteOpportunity(item) {
      this.opportunitydeleteoverlay = true;
      this.activeopportunity = {...item};
    },
    CreateOpportunity() {
      if(this.opportunitycreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('opportunities.create')){
        this.activeopportunity.author = this.user.id;
        this.addOpportunity(this.activeopportunity).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
            this.opportunitycreateoverlay = false;
            this.activeopportunity = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateOpportunity() {
      if(this.opportunityupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('opportunities.update')){
        this.updateOpportunity(this.activeopportunity).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
            this.opportunityupdateoverlay = false;               
            this.activeopportunity = {};
            this.UpdateOpportunities(response.data);
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    UpdateOpportunities(payload) {
      const edited_item_index=this.opportunities.findIndex(
          (item) => item.id === payload.id
        );
      if (edited_item_index !== -1)
          this.opportunities[edited_item_index] = payload;
    },
    DeleteOpportunity() {
      if(this.user && this.user.permission.map(i=>i.name).includes('opportunities.delete')){
        this.deleteOpportunity(this.activeopportunity).then(() => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.opportunitydeleteoverlay = false;
            this.activeopportunity = {};
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearOpportunity() {
      this.activeopportunity = {};
    },

    ...mapActions("opportunity", ["addOpportunity","updateOpportunity","deleteOpportunity"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/jobseeker.css");
</style>